<template>
  <div>
    <WritePost class="mb-3" />
    <b-overlay :show="isLoadingFirst">
      <div :class="scrollClass">
        <div v-for="(post, index) in posts" :key="index">
          <Post
            class="mb-3"
            :index="index"
            :post="post"
            @deletePost="deletePost($event)"
          />
        </div>
      </div>
      <b-overlay :show="isLoadingNext">
        <b-button class="mb-3" block v-if="hasNext" @click="fetchNext">
          Load more
        </b-button>
      </b-overlay>
    </b-overlay>
  </div>
</template>
<script>
import WritePost from "@/components/landing/WritePost.vue";
import Post from "@/components/landing/Post.vue";
import moment from "moment";

export default {
  props: ["hideScroll"],
  components: {
    WritePost,
    Post,
  },
  data() {
    return {
      isLoadingNext: false,
      isLoadingFirst: false,
    };
  },
  async created() {
    this.isLoadingFirst = true;
    await this.$store.dispatch("SocialPosts/fetchAll");
    this.isLoadingFirst = false;
  },
  methods: {
    async fetchNext() {
      this.isLoadingNext = true;
      await this.$store.dispatch("SocialPosts/fetchNext");
      this.isLoadingNext = false;
    },
  },
  computed: {
    scrollClass() {
      return this.hideScroll ? "" : "scrollableSocial";
    },
    posts() {
      return this.$store.state.SocialPosts.all;
    },
    hasNext() {
      return this.$store.state.SocialPosts.lastPagination?.next_page_url;
    },
  },
};
</script>
<style scoped>
.scrollableSocial {
  min-height: 75vh;
  max-height: 100vh;
  overflow-y: scroll;
  background: transparent;
}
</style>
