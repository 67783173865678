import { BaseModel } from "./base.model";

export class SocialPost extends BaseModel {
  constructor(
    public post: string,
    public user_id: string,
    public attachment_name?: string,
    public attachment_url?: string,
    public attachment_type?: string,
    public deleted_at?: string
  ) {
    super();
  }
}
