<template>
  <b-container>
    <b-row>
      <b-col>
        <SocialWall :hide-scroll="true" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import SocialWall from "@/components/landing/SocialWall.vue";

export default {
  name: "SocialView",
  components: {
    SocialWall,
  },
};
</script>
